import React from "react"
import { MainLayout } from "@components/layouts"
import { Seo } from "@components"

const NotFoundPage = () => (
  <MainLayout>
    <Seo title="404: Not found" />
    <div className="content has-text-centered">
      <h1 className="is-size-3">ไม่พบหน้าที่คุณค้นหา...</h1>
    </div>
    <div className="columns">
      <div className="column is-3"></div>
      <div className="column is-6">
        <figure className="image">
          <img src="/images/404.png" alt="404" />
        </figure>
      </div>
    </div>
  </MainLayout>
)

export default NotFoundPage
